export const commonPests = [
  {
    name: 'Ants',
    author: 'lilbittalove',
    image: 'https://live.staticflickr.com/3230/2871690741_311a836036.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Mole Cricket',
    author: 'Tobias b köhler',
    image: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Gryllotalpa_gryllotalpa_-_Europ%C3%A4ische_Maulwurfsgrille.jpg',
    licenseType: 'CC BY-SA 3.0',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0?ref=ccsearch&atype=html'
  },
  {
    name: 'White Grub',
    author: 'Memotions',
    image: 'https://live.staticflickr.com/31/245764465_d2218c851c.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Termites White Ants',
    author: 'Gnilenkov Aleksey',
    image: 'https://live.staticflickr.com/7184/6880327034_00a1e5ef2e_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Harvester Termite',
    author: 'Hodotermes_mossambicus',
    image: 'https://upload.wikimedia.org/wikipedia/commons/6/69/Hodotermes_mossambicus%2C_n%2C_Schanskop.jpg',
    licenseType: 'JMK / CC BY-SA',
    licenseLink: 'https://creativecommons.org/licenses/by-sa/3.0'
  },
  {
    name: 'Common Lawn Cricket',
    author: 'Museums Victoria\'s',
    image: 'https://live.staticflickr.com/802/26078048757_b0f5caa0b4_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  }
];
