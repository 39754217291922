import React from 'react';
import './Footer.scss';

const Footer = () => {

  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <p>&copy; Lawn-Pro Durban {currentYear}</p>
    </div>
  );
};

export default Footer;
