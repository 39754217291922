import React from 'react';
import './Services.scss';
import { services } from '../../../../Data/services-offered';
import ServicesCard from './ServicesCard/ServicesCard';

const renderServices = () => {
  return services.map(service => {
    return (
      <ServicesCard
        description={service.description}
        href={service.href}
        img={service.img}
        key={service.id}
        title={service.title}
      />
    );
  });
};

const Services = () => {
  return (
    <section className="services">
      <div className="services__container">
        <h1>Our Services</h1>
        <div className="services__list">
          { renderServices() }
        </div>
      </div>
    </section>
  );
};

export default Services;
