import React from 'react';
import { NavLink } from 'react-router-dom';
import './ServicesLayout.scss';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import ContactBanner from '../../ContactBanner/ContactBanner';

const ServicesLayout = (props) => {
  return (
    <div className='standard-page'>
      <div className="services__page">
        <Header/>
        <aside>
          <NavLink to='/services/seasonal-maintenance'>Seasonal Maintenance</NavLink>
          <NavLink to='/services/granular-fertilisers'>Granular Fertilisers</NavLink>
          <NavLink to='/services/weed-control'>Weed Control</NavLink>
          <NavLink to='/services/pest-and-disease-control'>Pest &amp; Disease Control</NavLink>
        </aside>
        <main>
          <div className='main-content'>
            {props.content}
          </div>
          <ContactBanner/>
        </main>
      </div>
      <Footer/>
    </div>
  );
};

export default ServicesLayout;
