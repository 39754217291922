import React from 'react';
import './ContactBanner.scss';

import ContactForm from '../ContactForm/ContactForm';

import emailIcon from '../../assets/images/mail.svg';
import locationIcon from '../../assets/images/location-icon.svg';
import logo from '../../assets/images/LawnPro.png';
import phoneIcon from '../../assets/images/phone.svg';

const Process = () => {
  return (
    <div className='contact-banner'>
      <div className='contact-banner__content'>
        <div className='contact-banner__content-info'>
          <h2>Don’t delay, contact us for your free 14&nbsp;point lawn analysis and quote.</h2>
          <p><a href='tel:+27832275512'><img src={phoneIcon} alt='phone'/>083&nbsp;227&nbsp;5512</a></p>
          <p><a href='mailto:billyl@lawnpro.co.za' ><img src={emailIcon} alt='email'/>billyl@lawnpro.co.za</a></p>
          <p><a href='https://goo.gl/maps/PFUStMPYEgf1tCnX6' target='_blank' rel='noopener noreferrer'><img src={locationIcon} alt='location'/>Umhlanga, South&nbsp;Africa</a></p>
          <img className='logo' src={logo} alt='Lawn-Pro'/>
        </div>
        <div className='contact-banner__content-info form'>
          <ContactForm/>
        </div>
      </div>
    </div>
  );
};

export default Process;
