export const commonDiseases = [
  {
    name: 'Brown Patch',
    author: 'Kris Lord',
    image: 'https://farm1.staticflickr.com/9/31495685426_6500fe88c5_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Dollar Spot',
    image: 'https://upload.wikimedia.org/wikipedia/commons/8/87/Dollarspot.jpg',
    author: 'Glenobear / Public domain',
  },
  {
    name: 'Rust',
    image: 'https://farm3.staticflickr.com/2797/5815755212_5c107e3a46_b.jpg',
    author: 'Plant pests and diseases',
    licenseType: 'CC PDM 1.0',
    licenseLink: 'https://creativecommons.org/publicdomain/mark/1.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Pythium Blight',
    author: 'Kris Lord',
    image: 'https://farm1.staticflickr.com/238/31417391641_7b96f37820_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Fairy Ring',
    image: 'https://upload.wikimedia.org/wikipedia/commons/9/96/Fairy_ring_on_a_suburban_lawn_100_1851.jpg',
    author: 'Mrs skippy / Public domain'
  },
  {
    name: 'Anthracnose',
    author: 'Kris Lord',
    image: 'https://live.staticflickr.com/48/30724437113_2048d80ccc_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  }
];
