import React, {Component } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import logo from './../../assets/images/LawnPro.png';
import MobileNavTrigger from '../MobileNavTrigger/MobileNavTrigger';

class Header extends Component {
  constructor(props){
    super(props);

    this.state = {
      dropdownVisible: false,
      isActive: false,
      scrollActive: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  componentDidMount(){
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('scroll', this.handleScroll);
  }

  toggleMobileNav = () => {
    this.setState(prevState => {
      return {
        isActive: prevState.isActive = !this.state.isActive
      };
    });
  }

  handleMobileNav = () => {
    this.setState(prevState => {
      return {
        isActive: prevState.isActive = false
      };
    });
  }

  handleDropdown = () => {
    this.setState(prevState => {
      return {
        dropdownVisible: !prevState.dropdownVisible
      };
    });
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState(prevState => {
        return {
          dropdownVisible: prevState.dropdownVisible = false
        };
      });
    }
  }

  handleScroll = () => {
    let scrollPosY = window.pageYOffset | document.body.scrollTop;
    scrollPosY > 100
      ? this.setState({ scrollActive: true })
      : this.setState({ scrollActive: false });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <header className={this.state.scrollActive ? 'header header--scrolled' : 'header'}>
        <div className="header__container">
          <Link to='/' >
            <div className={this.state.scrollActive ? 'header__logo header__logo--scrolled' : 'header__logo'}>
              <img src={logo} alt="Lawn-Pro Durban"/>
            </div>
          </Link>
          <div className={this.state.isActive ? 'header__nav header__nav--active' : 'header__nav'}>
            <ul>
              <li>
                <span onClick={this.handleDropdown} ref={this.setWrapperRef} className='drop-down'>Services<span className='arrow-down'></span></span>
                <div className={this.state.dropdownVisible ? 'dropdown-wrapper dropdown-wrapper--visible' : 'dropdown-wrapper'  }>
                  <ul className={this.state.scrollActive ? 'sub-nav sub-nav--scrolled' : 'sub-nav'}>
                    <li><Link to='/services/seasonal-maintenance' onClick={this.handleMobileNav}>Seasonal Maintenance</Link></li>
                    <li><Link to='/services/granular-fertilisers' onClick={this.handleMobileNav}>Granular Fertilisers</Link></li>
                    <li><Link to='/services/weed-control' onClick={this.handleMobileNav}>Weed Control</Link></li>
                    <li><Link to='/services/pest-and-disease-control' onClick={this.handleMobileNav}>Pest &amp; Disease Control</Link></li>
                  </ul>
                </div>
              </li>
              <li><Link to='/about-us' onClick={this.handleMobileNav}>About Us</Link></li>
              <li><Link to='/gallery' onClick={this.handleMobileNav}>Gallery</Link></li>
              <li><Link to='/contact' onClick={this.handleMobileNav}>Contact</Link></li>
            </ul>
            <Link to='/contact' className='button button--block' onClick={this.handleMobileNav}>Get a quote</Link>
          </div>
          <MobileNavTrigger toggleNav={this.toggleMobileNav} isActive={this.state.isActive}/>
        </div>
      </header>
    );
  }
};

export default Header;
