import React from 'react';
import './MobileNavTrigger.scss';

const MobileNavTrigger = (props) => {
  return (
    <div onClick={props.toggleNav} className={props.isActive ? 'mobile-nav-trigger mobile-nav-trigger--active' : 'mobile-nav-trigger'}>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </div>
  );
};

export default MobileNavTrigger;
