import React from 'react';
import { NavLink } from 'react-router-dom';

import '../Button/Button.scss';

const Button = (props) => {
  return (
    <NavLink to={props.to} className={props.type ? `button ${props.type}` : 'button'}>{props.text}</NavLink>
  );
};

export default Button;
