import React from 'react';
import './ServicesCard.scss';

import Button from '../../../../../Components/Button/Button';

const ServicesCard = (props) => {
  return (
    <div className="services-card">
      <img className="icon" src={props.img} alt={props.title}/>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      <Button to={props.href} text='Read more' type='button--text' />
    </div>
  );
};

export default ServicesCard;
