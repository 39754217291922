import gallery1 from './../assets/images/gallery-1.jpg';
import gallery2 from './../assets/images/gallery-2.jpg';
import gallery3 from './../assets/images/gallery-3.jpg';
import gallery4 from './../assets/images/gallery-4.jpg';
import gallery5 from './../assets/images/gallery-5.jpg';
import gallery6 from './../assets/images/gallery-6.jpg';
import gallery7 from './../assets/images/gallery-7.jpg';
import gallery8 from './../assets/images/gallery-8.jpg';
import gallery9 from './../assets/images/gallery-9.jpg';

export const photoGallery = [
  {
    name: 'Residential garden',
    image: gallery1,
    author: '© Billy Lyle, Lawn-Pro',
  },
  {
    name: 'Residential garden',
    image: gallery2,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Residential garden',
    image: gallery3,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Residential garden',
    image: gallery4,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Complex Common Area',
    image: gallery5,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Boutique Hotel',
    image: gallery6,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Boutique Hotel',
    image: gallery7,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Residential garden',
    image: gallery8,
    author: '© Billy Lyle, Lawn-Pro'
  },
  {
    name: 'Residential garden',
    image: gallery9,
    author: '© Billy Lyle, Lawn-Pro'
  },
];
