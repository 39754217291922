export const commonWeeds = [
  {
    name: 'Common Dandelion',
    image: 'https://live.staticflickr.com/2890/33814293950_6d8f3560ec_b.jpg',
    author: 'Tobyotter',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Creeping Sorrel',
    image: 'https://farm1.staticflickr.com/437/19709393620_58b3ce22f2_b.jpg',
    author: 'patrick_standish',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Green Kyllinga',
    author: 'Starr Environmental',
    image: 'https://live.staticflickr.com/1590/24335675764_eb97d57966_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Yellow Nutsedge',
    author: 'The NYSIPM Image Gallery',
    image: 'https://live.staticflickr.com/5494/18834832472_51b2413b8c_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Wintergrass',
    author: 'Rasbak',
    image: 'https://upload.wikimedia.org/wikipedia/commons/7/77/Poa_annua.jpg',
    licenseType: 'CC BY-SA 3.0',
    licenseLink: 'http://creativecommons.org/licenses/by-sa/3.0/'
  },
  {
    name: 'White Clover',
    author: 'Lawn Weeds',
    image: 'https://live.staticflickr.com/65535/48293022351_6bda4ee447_b.jpg',
    licenseType: 'CC BY 2.0',
    licenseLink: 'https://creativecommons.org/licenses/by/2.0/?ref=ccsearch&atype=html'
  },
  {
    name: 'Prostrate Spurge',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/3c/Chamaesyce.jpg',
    author: 'Hardyplants at English Wikipedia / Public domain'
  },
];
