import React, { Component } from 'react';
import './ContactForm.scss';
import axios from 'axios';
import ContactFormSuccess from './ContactFormSuccess';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullname: '',
      phone: '',
      email: '',
      message: '',
      mailSent: false,
      errorMsg: ''
    };

  }

  formSubmit = (event) => {
    event.preventDefault();

    let data = {
      fullName: this.state.fullname,
      phone: this.state.phone,
      email: this.state.email,
      message: this.state.message,
    };

    axios.post(`${process.env.REACT_APP_HOST}api/contact`, data)
      .then(res => {
        if (res.request.status === 200) {
          this.setState({ mailSent: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ errorMsg: 'Oops... looks like we had trouble sending this. Please try again later.' });
      });
  }

  handleInput = (event) => {
    const name = event.target.name;

    this.setState({
      [name]: event.target.value
    });
  }

  render() {
    if (this.state.mailSent === false) {
      return (
        <form className='contact-form' onSubmit={this.formSubmit}>
          <div className='contact-form__form-group'>
            <label htmlFor='fullname'>Full Name: <span className='required-field'>*</span></label>
            <div className='contact-form__form-group__inputs'>
              <input type='text' name='fullname' id='fullname' required value={this.state.name} onChange={this.handleInput} />
              <div className='validation-error'>* A full name is required</div>
            </div>
          </div>

          <div className='contact-form__form-group'>
            <label htmlFor='phone'>Phone: <span className='required-field'>*</span></label>
            <div className='contact-form__form-group__inputs'>
              <input type="tel" name="phone" id='phone' pattern="[0-9]{10}" required value={this.state.phone} onChange={this.handleInput}></input>
              <div className='validation-error'>* A phone number is required</div>
            </div>
          </div>

          <div className='contact-form__form-group'>
            <label htmlFor='email'>Email: <span className='required-field'>*</span></label>
            <div className='contact-form__form-group__inputs'>
              <input type='email' name='email' id='email' required value={this.state.email} onChange={this.handleInput} />
              <div className='validation-error'>* An email address is required</div>
            </div>
          </div>

          <div className='contact-form__form-group'>
            <label htmlFor='message'>Message: <span className='required-field'>*</span></label>
            <div className='contact-form__form-group__inputs'>
              <textarea name='message' id='message' required value={this.state.message} onChange={this.handleInput} rows='6' />
              <div className='validation-error'>* A message is required</div>
            </div>
          </div>

          <div className='contact-form__form-group'>
            <label>&nbsp;</label>
            <div className='contact-form__form-group__inputs'>
              <input className='submit' type="submit" value="Submit" />
            </div>
          </div>
          <p className='submit-error'>{this.state.errorMsg}</p>

        </form>
      );
    } else {
      return (
        <ContactFormSuccess
          name={this.state.fullname}
          phone={this.state.phone}
          email={this.state.email}
          message={this.state.message}
        />
      );
    }
  }
}

export default ContactForm;
