import React from 'react';
import ServicePageHeading from '../../Components/ServicePageHeading/ServicePageHeading';
import granularIcon from './../../assets/images/granular-icon.svg';

import { Helmet } from 'react-helmet';

const GranularFertilisers = () => {
  return (
    <div>
      <Helmet>
        <title>Lawn-Pro Durban | Granular Fertilisers</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Granular Lawn Fertilisers: special fertilisers, healthy lawn, luscious and green lawn." />
        <meta name="keywords" content="Lawn-Pro, fertilisers, lawn booster, general insecticides, insect control, weed control, lawn analysis, lawn maintenance"></meta>
      </Helmet>
      <ServicePageHeading title='Granular Fertilisers' icon={granularIcon} />
      <p>Using the <strong>correct blend of fertiliser</strong> for specific grass-types and conditions is of the utmost importance in establishing a healthy lawn. Lawn-Pro has spent many years in research and development, and have designed their own blend of <strong>prescription fertilisers</strong>.</p>
      <p>Used on a regular basis it will ensure that your lawn stays healthy and steadily supplied with the correct amount of nutrients keeping it luscious and green all year round.</p>
      <div className='fertiliser-img'>&nbsp;</div>
    </div>
  );
};

export default GranularFertilisers;
