import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound';
import AboutPage from './Pages/About/AboutPage';
import ServicesLayout from './Components/Layout/Services/ServicesLayout';
import SeasonalMaintenance from './Pages/Services/SeasonalMaintenance';
import GranularFertilisers from './Pages/Services/GranularFertilisers';
import WeedControl from './Pages/Services/WeedControl';
import DiseaseControl from './Pages/Services/DiseaseControl';
import Gallery from './Pages/Gallery/Gallery';
import ContactPage from './Pages/Contact/ContactPage';

import ReactGA from 'react-ga';
ReactGA.initialize(`${process.env.REACT_APP_GA}`);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <Switch>
      <Route exact path='/' render={() => ( <Layout pageType='home' page={<Home/>}/> )} />
      <Route exact path='/about-us' render={() => ( <Layout page={<AboutPage/>}/> )} />
      <Route exact path='/services' render={() => ( <ServicesLayout content={<SeasonalMaintenance/>}/> )} />
      <Route exact path='/services/seasonal-maintenance' render={() => ( <ServicesLayout content={<SeasonalMaintenance/>}/> )} />
      <Route exact path='/services/granular-fertilisers' render={() => ( <ServicesLayout content={<GranularFertilisers/>}/> )} />
      <Route exact path='/services/weed-control' render={() => ( <ServicesLayout content={<WeedControl/>}/> )} />
      <Route exact path='/services/pest-and-disease-control' render={() => ( <ServicesLayout content={<DiseaseControl/>}/> )} />
      <Route exact path='/gallery' render={() => ( <Layout page={<Gallery/>}/> )} />
      <Route exact path='/contact' render={() => ( <Layout page={<ContactPage/>}/> )} />
      <Route component={NotFound}/>
    </Switch>
  );
};

export default App;
