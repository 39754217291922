import React from 'react';
import './PhotoGallery.scss';

const renderGallery = (items) => {
  return items.map((item, index) => {
    return (
      <figure key={index}>
        <img src={item.image} alt={item.name} title={item.name}/>
        <figcaption>{item.name}</figcaption>
        { item.author ? <p className='author-cred'>Image: {item.author} <a href={item.licenseLink} target='_blank' rel='noopener noreferrer'>{item.licenseType}</a></p> : null }
      </figure>
    );
  });
};

const PhotoGallery = (props) => {
  return (
    <div className={`photo-gallery ${props.modifier ? props.modifier : ''}`}>
      { renderGallery(props.gallery) }
    </div>
  );
};

export default PhotoGallery;
