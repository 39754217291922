import React, { Fragment } from 'react';
import './ContactPage.scss';

import Map from './../../Components/Map/Map';
import ContactBanner from '../../Components/ContactBanner/ContactBanner';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Lawn-Pro Durban | Contact</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Professional, reliable lawn care service based in Umhlanga, offering services to clients in Durban, Ballito, Westville, Kloof, Hillcrest and surrounding areas." />
        <meta name="keywords" content="Lawn-Pro, Lawn care service Durban, 14 point lawn analysis, lawn service quote, Umhlanga, Durban, Ballito, Westville, Kloof, Hillcrest"></meta>
      </Helmet>
      <div className='contact-overlay'>
        <div className='contact-page'>
          <h1>We are based in <strong>Umhlanga</strong> and offer our services to clients in <strong>Durban,</strong> <strong>Ballito,</strong> <strong>Westville,</strong> <strong>Kloof,</strong> <strong>Hillcrest</strong> and surrounding areas.</h1>
        </div>
      </div>
      <ContactBanner />
      <Map />
    </Fragment>
  );
};

export default ContactPage;
