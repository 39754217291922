import React from 'react';
import ServicePageHeading from '../../Components/ServicePageHeading/ServicePageHeading';
import diseaseIcon from './../../assets/images/pest-control-icon.svg';

import { commonDiseases } from './../../Data/commonDiseases';
import PhotoGallery from '../../Components/PhotoGallery/PhotoGallery';
import { commonPests } from '../../Data/commonPests';
import { Helmet } from 'react-helmet';

const DiseaseControl = () => {
  return (
    <div>
      <Helmet>
        <title>Lawn-Pro Durban | Pest &amp; Disease Control</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Pest and Disease Control: Pest and disease control for your lawn, grass, garden and turf." />
        <meta name="keywords" content="Lawn-Pro, lawn pests, lawn insects, garden pests"></meta>
      </Helmet>
      <ServicePageHeading title='Pest &amp; Disease Control' icon={diseaseIcon} />
      <h2>Pest Control</h2>
      <p>There will always be insects in your garden but only pose a problem when their count is out of control. Insects can fly and crawl into your garden, so for the treatment to be effective it has to be applied every 13 weeks. The insect control agents can only work effectively after the intruders life-cycle has been exterminated.</p>
      <p>Insects lay eggs and these eggs are not affected by the first treatment. They need to hatch to be taken care of on the next treatment.</p>
      <p>However, if mole crickets or termites are present in the lawn they will require a special treatment which will be quoted for separately.</p>
      <p>Below are some of the more common pests found in lawns:</p>
      <PhotoGallery gallery={commonPests} modifier='photo-gallery--weed-control' />
      <hr />
      <h2>Disease Control</h2>
      <p>Most lawn diseases appear in the hot wet months of the year. When a disease appears on your lawn, rapid action is necessary to prevent it from spreading and destroying your lawn.</p>
      <p>Always irrigate your lawn during the early mornings, just before sunrise if possible. This is the coolest part of the day and evaporation will not play such a major role as when you irrigate during the middle of the day.</p>
      <p>Irrigating in the late afternoon in areas where dew may occur, will lengthen the dew period increasing the chances of a dreaded disease occurring in your lawn.</p>
      <p>Below are some of the more common diseases found in lawns:</p>
      <PhotoGallery gallery={commonDiseases} modifier='photo-gallery--weed-control' />
    </div>
  );
};

export default DiseaseControl;
