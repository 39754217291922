import React from 'react';
import './PageImage.scss';

const PageImage = (props) => {
  return (
    <div className='full-page-img'>
      <img src={props.image} alt={props.alt}/>
    </div>
  );
};

export default PageImage;
