import React from 'react';
import './ServicePageHeading.scss';

const ServicePageHeading = (props) => {
  return (
    <div className='service-heading'>
      <h1>{props.title}</h1>
      <img className='service-heading__icon' src={props.icon} alt={props.title}/>
    </div>
  );
};

export default ServicePageHeading;
