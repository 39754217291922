import React from 'react';
import './About.scss';
import Button from '../../../../Components/Button/Button';
import approvalIcon from './../../../../assets/images/approval.svg';

const About = () => {
  return (
    <section className="about">
      <div className="about__details">
        <div className="about__details-content">
          <h1>About Us</h1>
          <div className='about__details-content__info'>
            <div className='about__details-content__info-content'>
              <p>We provide our clients with a <strong>reliable</strong> and <strong>professional</strong> lawn care service, offering a variety of different programs to suit individual needs.</p>
              <p>We visit your lawn 4 times a year, once every season and evenly apply our magic, unique and special blend of fertilisers and agents to control weeds, insects and diseases for the best looking lawn in town!</p>
              <p>We are based in Umhlanga and offer our services to clients in <strong>Durban</strong>, <strong>Ballito</strong>, <strong>Westville</strong>, <strong>Kloof</strong>, <strong>Hillcrest</strong> and surrounding areas.</p>
              <Button to='/about-us' text='Read more' type='button--text'/>
            </div>
            <div className='about__details-content__info-content-right'>
              <p className="certified">
                <img src={approvalIcon} alt='registered and approved'/>
                Lawn-Pro franchisees are registered and approved by the National Department of Agriculture.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
