import React from 'react';

const ContactFormSuccess = (props) => {
  return (
    <div className='contact-form__success'>
      <h2>Thanks, <span className='contact-form__success--highlight'>{props.name}</span>!</h2>
      <h3>We'll be in touch shortly.</h3>
      <br/>
      <p>You submitted the following details:</p>
      <div className='submit-details'>
        <div className='submit-details__group'>
          <p><strong>Name:</strong> {props.name}</p>
        </div>
        <div className='submit-details__group'>
          <p><strong>Phone:</strong> {props.phone}</p>
        </div>
        <div className='submit-details__group'>
          <p><strong>Email:</strong> {props.email}</p>
        </div>
        <div className='submit-details__group'>
          <p><strong>Message:</strong></p>
          <p>{props.message}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactFormSuccess;
