import React from 'react';
import './HeroBanner.scss';
import Button from '../../../../Components/Button/Button';

const HeroBanner = () => {
  return (
    <section className="hero-banner">
      <div className="intro">
        <div className='intro__content'>
          <h1>Professional, reliable lawn care service.</h1>
          <p>We offer a variety of different programs to suit individual needs.</p>
          <Button to='/contact' text='GET A QUOTE' type='button--block'/>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
