import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const Layout = (props) => {
  return (
    <div className={props.pageType === 'home' ? 'home-page' : 'standard-page'}>
      <Header/>
      <main>{props.page}</main>
      <Footer/>
    </div>
  );
};

export default Layout;
