import React, { Fragment } from 'react';

import ServicePageHeading from '../../Components/ServicePageHeading/ServicePageHeading';
import seasonalIcon from './../../assets/images/calendar-icon.svg';
import PageImage from '../../Components/PageImage/PageImage';
import smImage from '../../assets/images/gallery-9.jpg';
import { Helmet } from 'react-helmet';

const SeasonalMaintenance = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Lawn-Pro Durban | Seasonal Maintenance</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Seasonal lawn maintenance: fertilisers, lawn booster, general insecticides, insect control, weed control, lawn analysis." />
        <meta name="keywords" content="Lawn-Pro, fertilisers, lawn booster, general insecticides, insect control, weed control, lawn analysis, lawn maintenance"></meta>
      </Helmet>
      <ServicePageHeading title='Seasonal Maintenance' icon={seasonalIcon} />
      <p>A Lawn-Pro lawn specialist will carefully scrutinize your lawn and undertake a free 14 point lawn analysis. He will look for insects, weeds, diseases and the general condition of the lawn before prescribing a suitable maintenance plan.</p>
      <p>The Lawn-Pro seasonal program is applied every 13 weeks and includes the following:</p>
      <ol>
        <li>The even application of <strong>Lawn-Pro’s prescription blend of fertilisers</strong>.</li>
        <li>Application of <strong>Lawn-Pro’s unique lawn booster</strong> consisting of live micro-organisms.</li>
        <li>The application of <strong>Lawn-Pro’s instant green super</strong> containing essential trace elements.</li>
        <li>Overall application of <strong>general insecticides</strong> to control insects that may damage the lawn.</li>
        <li>Application of <strong>selective herbicides</strong> to lawn areas to control weeds.</li>
        <li>The mixing in of <strong>ph buffers and wetting agents</strong> to enable the products to be easily absorbed by the plant.</li>
        <li>Follow up and touch up visits may be quoted for separately depending on the extent of the problem.</li>
      </ol>
      <p>All products applied are safe for humans, birds and animals alike.</p>
      <PageImage image={smImage} alt='Seasonal Maintenance' />
    </Fragment>
  );
};

export default SeasonalMaintenance;
