import React from 'react';
import ServicePageHeading from '../../Components/ServicePageHeading/ServicePageHeading';
import weedControlIcon from './../../assets/images/weed-control-icon.svg';
import PhotoGallery from '../../Components/PhotoGallery/PhotoGallery';
import { commonWeeds } from '../../Data/commonWeeds';
import { Helmet } from 'react-helmet';

const WeedControl = () => {
  return (
    <div>
      <Helmet>
        <title>Lawn-Pro Durban | Weed Control</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Weed control: Lawn-Pro will identify and control weeds on your lawn and paved areas." />
        <meta name="keywords" content="Lawn-Pro, lawn weeds, weed control, garden weeds"></meta>
      </Helmet>
      <ServicePageHeading title='Weed Control' icon={weedControlIcon} />
      <p>When a weed problem appears it is better to act immediately than to let it get out of control.</p>
      <p>As a Lawn-Pro franchisee, we are properly trained to identify and control weeds on your lawn and paved areas.</p>
      <p>Always prevent weeds from producing seeds by mowing your lawn regularly. Some weeds grow lower than the cutting height of your lawnmower and it will be difficult to prevent them from reproducing.</p>
      <p>Below are some of the more common weeds found in lawns:</p>
      <PhotoGallery gallery={commonWeeds} modifier='photo-gallery--weed-control' />
    </div>
  );
};

export default WeedControl;
