import React from 'react';
import Gallery from '../../Components/PhotoGallery/PhotoGallery';
import './Gallery.scss';
import { Helmet } from 'react-helmet';

import { photoGallery } from './../../Data/photoGallery';

const PhotoGallery = () => {
  return (
    <div className='gallery'>
      <Helmet>
        <title>Lawn-Pro Durban | Gallery</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - View our gallery. Lawn Care, Lawn Maintenance, Turf, Green Grass, Durban, Lawnpro, Garden, Lawn Doctor." />
        <meta name="keywords" content="Lawn-Pro, Lawn care service Durban, green grass, healthy grass, luscious lawn, perfect garden, grass pictures, lawn gallery"></meta>
      </Helmet>
      <br />
      <Gallery gallery={photoGallery} modifier='photo-gallery--past-work' />
    </div>
  );
};

export default PhotoGallery;
