import React from 'react';
import HeroBanner from './HomeSections/HeroBanner/HeroBanner';
import Services from './HomeSections/Services/Services';
import About from './HomeSections/About/About';
import Divider from '../../Components/Divider/Divider';
import grassPhoto from '../../assets/images/grass.jpeg';
import ContactBanner from '../../Components/ContactBanner/ContactBanner';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div className="home-page">
      <Helmet>
        <title>Lawn-Pro Durban</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - Professional, reliable lawn care service. Offering a variety of different programs to suit individual needs." />
        <meta name="keywords" content="Lawn-Pro, Lawn care service Durban, grass, green grass, healthy grass, garden care, turf, lawn weeds, lawn pests, lawn disease, professional lawn service"></meta>
      </Helmet>
      <HeroBanner />
      <Services />
      <Divider text='Grow a greener, healthier lawn.' image={grassPhoto} />
      <About />
      <ContactBanner />
    </div>
  );
};

export default Home;
