import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/LawnPro.png';

const NotFound = () => {
  return (
    <div className='not-found'>
      <img src={logo} alt='Lawn-Pro'/>
      <h3>Looks like we can't find that page...</h3>
      <p><strong>Here are some helpful links:</strong></p>
      <ul>
        <li><Link to='/' >Home Page</Link></li>
        <li><Link to='/services/seasonal-maintenance' >Services</Link></li>
        <li><Link to='/about-us' >About Us</Link></li>
        <li><Link to='/gallery' >Gallery</Link></li>
        <li><Link to='/contact' >Contact</Link></li>
      </ul>
    </div>
  );
};

export default NotFound;
