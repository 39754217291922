import granularIcon from './../assets/images/granular-icon.svg';
import weedControlIcon from './../assets/images/weed-control-icon.svg';
import diseaseControlIcon from './../assets/images/pest-control-icon.svg';
import seasonalVisits from './../assets/images/calendar-icon.svg';

export const services = [
  {
    id: 1,
    img: seasonalVisits,
    title: 'Seasonal Maintenance',
    description: 'A Lawn-Pro lawn specialist will carefully scrutinise your lawn and undertake a free 14 point lawn analysis. Our seasonal program is applied every 13 weeks.',
    href: '/services/seasonal-maintenance'
  },
  {
    id: 2,
    img: granularIcon,
    title: 'Granular fertilisers',
    description: 'Lawn-Pro has spent many years in research and development, and have designed their own blend of prescription fertilisers.',
    href: '/services/granular-fertilisers'
  },
  {
    id: 3,
    img: weedControlIcon,
    title: 'Weed Control',
    description: 'When a weed problem appears it is better to act immediately than to let it get out of control.',
    href: '/services/weed-control'
  },
  {
    id: 4,
    img: diseaseControlIcon,
    title: 'Pest & Disease Control',
    description: 'When pests or disease appear on your lawn, rapid action is necessary to prevent it from spreading and destroying your lawn.',
    href: '/services/pest-and-disease-control'
  },
];
