import React from 'react';
import './Map.scss';

const Map = () => {
  return (
    <div className='map'>
      <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3464.7377353991783!2d31.077351315628132!3d-29.727355323561806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjnCsDQzJzM4LjUiUyAzMcKwMDQnNDYuMyJF!5e0!3m2!1sen!2sza!4v1588146220904!5m2!1sen!2sza' width='600' height='300' frameBorder='0' style={{border:'0'}} allowFullScreen='' aria-hidden='false' tabIndex='0' title='Location'></iframe>
    </div>
  );
};

export default Map;
