import React from 'react';
import './PageIntro.scss';

const PageIntro = (props) => {
  return (
    <div className='page-intro'>
      <h1>{props.title}</h1>
    </div>
  );
};

export default PageIntro;
