import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './AboutPage.scss';
import PageIntro from '../../Components/PageIntro/PageIntro';
import ContactBanner from '../../Components/ContactBanner/ContactBanner';
import approvalIcon from '../../assets/images/approval.svg';
import galleryIcon from '../../assets/images/galleryIcon.svg';
import experienceIcon from '../../assets/images/experienceIcon.svg';
import tickIcon from '../../assets/images/tickIcon.svg';
import Map from '../../Components/Map/Map';
import { Helmet } from 'react-helmet';

const AboutPage = () => {

  const currentYear = new Date().getFullYear();

  return (
    <Fragment>
      <Helmet>
        <title>Lawn-Pro Durban | About Us</title>
        <meta
          name="description"
          content="Lawn-Pro Durban - We are based in Umhlanga and offer our services to clients in Durban, Ballito, Westville, Kloof, Hillcrest and surrounding areas." />
        <meta name="keywords" content="Lawn-Pro, Lawn care service Durban, weed control, pest control, disease control, insect control, green grass, healthy grass"></meta>
      </Helmet>
      <div className='about-page'>
        <PageIntro title='About Us' />
        <div className='about-page__content'>
          <div className='about-page__content-text'>
            <h3>We provide our clients with a <strong>reliable</strong> and <strong>professional</strong> lawn care service. Offering a variety of different programs to suit individual&nbsp;needs.</h3>
            <p>We visit your lawn <strong>4 times a year</strong>, once <strong>every season</strong> and evenly apply our magic, unique and special blend of fertilisers and agents to control weeds, insects and diseases for the best looking lawn in town!</p>
            <p>The <strong>Lawn-Pro</strong> franchise in <strong>Durban</strong> has been in operation since 2011 and is run by Billy Lyle. With over <strong>{currentYear - 2011} years of experience</strong> in the industry, we service a wide variety of clients. From private residential gardens to complexes, estates and hotels.</p>
            <p>Lawn-Pro is registered and approved by the National Department of&nbsp;Agriculture.</p>
            <p>We are based in <strong>Umhlanga</strong> and offer our services to clients in <strong>Durban</strong>, <strong>Ballito</strong>, <strong>Westville</strong>, <strong>Kloof</strong>, <strong>Hillcrest</strong> and surrounding areas.</p>
          </div>
          <div className='about-page__content-highlights'>
            <div className='about-highlights'>
              <img src={experienceIcon} alt='Experience' />
              <p>Over <span>{currentYear - 2011}</span> years experience in&nbsp;the industry</p>
            </div>
            <div className='about-highlights'>
              <img src={tickIcon} alt='Professional' />
              <p>A reliable and professional&nbsp;service</p>
            </div>
            <div className='about-highlights'>
              <img src={approvalIcon} alt='Registered' />
              <p>Registered and approved by the National Department of&nbsp;Agriculture</p>
            </div>
            <div className='about-highlights'>
              <img src={galleryIcon} alt='Gallery' />
              <p><Link to='/gallery'>View our gallery</Link> to see some of our work</p>
            </div>
          </div>
        </div>
      </div>
      <Map />
      <ContactBanner />
    </Fragment>
  );
};

export default AboutPage;
